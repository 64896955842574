import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useIdleTimer from '../hooks/useIdleTimer'
import useSound from 'use-sound'
import instructions from '../assets/videos/instructions.webm'
import instructionsFr from '../assets/videos/instructions-fr.webm'
import click from '../assets/sfx/click.webm'

import MasterLayout from '../layout/Master'

const Instruction = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const [playClick] = useSound(click, { volume: 0.2 })

  const goNext = () => {
    playClick()

    setTimeout(() => {
      navigate('/game')
    }, 250)
  }

  useIdleTimer()

  return (
    <MasterLayout>
      <div onClick={goNext} className="relative cursor-pointer">
        {i18n.language === 'en' ? (
          <video
            src={instructions}
            autoPlay
            loop
            muted
            hwz="z-index:-1"
            playsInline
            className="w-[100%] h-screen"
          />
        ) : (
          <video
            src={instructionsFr}
            autoPlay
            loop
            muted
            hwz="z-index:-1"
            playsInline
            className="w-[100%] h-screen"
          />
        )}
      </div>
    </MasterLayout>
  )
}
export default Instruction

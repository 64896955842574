import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      next: 'Next',
      'first-name': 'First Name',
      'last-name': 'Last Name',
      phone: 'Phone',
      'i-agree':
        'By accepting, you agree to share your information with Skip the Dishes in accordance with our security and confidentiality policy.',
      submit: 'Submit',
      'terms-modal-title': 'Terms & Conditions',
    },
  },
  fr: {
    translation: {
      next: 'Suivant',
      'first-name': 'Prénom',
      'last-name': 'Nom',
      phone: 'Téléphone',
      'i-agree':
        'En acceptant, vous consentez à partager vos informations avec Vidéotron en accord avec notre politique de sécurité et confidentialité.',
      submit: 'Soumettre',
      'terms-modal-title': 'Termes et Conditions',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n

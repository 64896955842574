import LanguageToggle from '../components/LanguageToggle'

const MasterLayout = ({ children }) => {
  return (
    <div
      id="master-layout"
      className="bg-black bg-no-repeat bg-cover w-[100%] h-screen relative flex flex-col overflow-hidden"
    >
      <LanguageToggle />
      {children}
    </div>
  )
}

export default MasterLayout

import { initializeApp } from 'firebase/app'
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAhWL-bPOTkEe0FLcWOZgVK-jTjRV22Gx4',
  authDomain: 'nars-cosmetics-405f6.firebaseapp.com',
  projectId: 'nars-cosmetics-405f6',
  storageBucket: 'nars-cosmetics-405f6.appspot.com',
  messagingSenderId: '546939080451',
  appId: '1:546939080451:web:bb591d47c990393477e336',
  measurementId: 'G-Y2T86JGV82',
}

const app = initializeApp(firebaseConfig)

initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
})

const db = getFirestore(app)

export { db }

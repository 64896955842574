import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageToggle = () => {
  const { i18n } = useTranslation()

  const toggleLanguage = () => {
    i18n.language === 'fr'
      ? i18n.changeLanguage('en')
      : i18n.changeLanguage('fr')

    localStorage.setItem('lang', i18n.language)

    console.log(i18n.language)
  }

  return (
    <div
      className="text-white text-2xl text-center w-1/6 h-1/6 absolute top-12 right-5 z-[99999]"
      onClick={toggleLanguage}
    >
      <div className="border-2 p-2">{i18n.language === 'fr' ? 'EN' : 'FR'}</div>
    </div>
  )
}

export default LanguageToggle

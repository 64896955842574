import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useIdleTimer from '../hooks/useIdleTimer'
import useSound from 'use-sound'
import { db } from '../firebase/config'
import { collection, addDoc, Timestamp } from 'firebase/firestore'
import thankYou from '../assets/videos/thank-you.webm'
import thankYouFr from '../assets/videos/thank-you-fr.webm'
import click from '../assets/sfx/click.webm'

import MasterLayout from '../layout/Master'

const saveData = async (gift) => {
  const kioskId = localStorage.getItem('kiosk-id')

  const GIFTS = ['Deluxe Mini', 'Sample']

  await addDoc(collection(db, `k:${kioskId}`), {
    gift: GIFTS[gift],
    createdAt: Timestamp.now(),
  })
}

const ThankYou = () => {
  const lang = localStorage.getItem('lang')

  const gift = localStorage.getItem('gift')
    ? JSON.parse(localStorage.getItem('gift'))
    : null

  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const [playClick] = useSound(click, { volume: 0.2 })

  useEffect(() => {
    i18n.changeLanguage(lang)

    saveData(gift)
  }, [])

  const goNext = () => {
    playClick()

    navigate('/')
  }

  useIdleTimer()

  return (
    <MasterLayout>
      <div onClick={goNext} className="relative cursor-pointer">
        {lang === 'en' ? (
          <video
            src={thankYou}
            autoPlay
            loop
            muted
            hwz="z-index:-1"
            playsInline
            className="w-[100%] h-screen"
          />
        ) : (
          <video
            src={thankYouFr}
            autoPlay
            loop
            muted
            hwz="z-index:-1"
            playsInline
            className="w-[100%] h-screen"
          />
        )}
      </div>
    </MasterLayout>
  )
}
export default ThankYou

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import MasterLayout from '../layout/Master'

const Game = () => {
  //const lang = localStorage.getItem('lang')

  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const detectStorageChange = (e) => {
    const { reset, gift } = e.storageArea

    if (reset) navigate('/')
    if (reset) sessionStorage.removeItem('hasRefreshed')

    if (gift) {
      setTimeout(() => {
        sessionStorage.removeItem('hasRefreshed')

        navigate('/thank-you')
      }, 4000)
    }
  }

  useEffect(() => {
    window.addEventListener('storage', detectStorageChange)

    return () => {
      window.removeEventListener('storage', detectStorageChange)
    }
  }, [])

  useEffect(() => {
    const hasRefreshed = sessionStorage.getItem('hasRefreshed')

    if (!hasRefreshed) {
      sessionStorage.setItem('hasRefreshed', 'true')

      window.location.reload()
    }
  }, [])

  return (
    <MasterLayout>
      <div className="w-full h-full fixed top-0 left-0 z-50">
        <iframe
          src="./nars-game/index.html"
          id="nars-game-iframe"
          title="nars-game"
          allow="autoplay"
          className="w-full h-full"
        />
      </div>
    </MasterLayout>
  )
}

export default Game

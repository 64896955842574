import { Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Instruction from './pages/Instruction'
import Game from './pages/Game'
import ThankYou from './pages/ThankYou'
import KioskId from './components/KioskId'
import ShufflingSpeed from './components/ShufflingSpeed'
import NumOfGifts from './components/NumOfGifts'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/instruction" element={<Instruction />} />
      <Route path="/game" element={<Game />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/k/:kioskId" element={<KioskId />} />
      <Route path="/s/:speed" element={<ShufflingSpeed />} />
      <Route path="/g/:numOfGifts" element={<NumOfGifts />} />
    </Routes>
  )
}

export default App
